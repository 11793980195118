import { ref, onMounted, getCurrentInstance } from "@vue/composition-api";
import axios from "axios";

import { useI18n } from "@/libs/i18n";
import { getDetail } from "@/api/product";

import { ProductDetail } from "@/components/Product/Types";

export default (storeUrl: string, productNo: string) => {
  const instance = getCurrentInstance();
  const { t } = useI18n();

  const productDetail = ref<ProductDetail>();
  const isLoading = ref(false);

  const fetchProductDetail = async (storeUrl: string, productNo: string) => {
    try {
      isLoading.value = true;
      productDetail.value = await getDetail(storeUrl, productNo);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorCode = error.response?.data.code;

        if (errorCode === 323) {
          alert(t("PRODUCT_NOT_IN_STORE_ERROR_MESSAGE"));
        } else {
          alert(t("PRODUCT_DETAIL_ERROR_MESSAGE"));
        }
      } else {
        alert(t("PRODUCT_DETAIL_ERROR_MESSAGE"));
      }
      instance?.proxy.$router.replace({ name: "Myshop" });
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(async () => {
    await fetchProductDetail(storeUrl, productNo);
  });

  return {
    productDetail,
    isLoading,

    fetchProductDetail,
  };
};
