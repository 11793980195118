






























import { computed, defineComponent, ref, watch } from "vue-demi";

import BottomFixedModal from "@/components/Common/BottomFixedModal.vue";
import MainTextInput from "@/components/Common/MainTextInput.vue";

export default defineComponent({
  components: {
    BottomFixedModal,
    MainTextInput,
  },
  emits: ["submit", "close"],
  props: {
    answerCode: {
      type: String,
    },
    isValid: {
      type: Boolean,
    },
    isError: {
      type: Boolean,
    },
  },
  setup(props, context) {
    const promotionCode = ref("");

    const isUncontrolled = computed(() => props.answerCode != null);
    const uncontrolledValid = ref(false);
    const integratedValid = computed(
      () => props.isValid || uncontrolledValid.value
    );
    const uncontrolledError = ref(false);
    const integratedError = computed(
      () => props.isError || uncontrolledError.value
    );

    const onInput = (value: string) => {
      promotionCode.value = value;

      context.emit("input", promotionCode.value);
    };
    watch(promotionCode, (newPromotionCode) => {
      if (isUncontrolled.value && newPromotionCode === props.answerCode) {
        uncontrolledValid.value = true;
        uncontrolledError.value = false;
      } else if (
        isUncontrolled.value &&
        newPromotionCode !== props.answerCode
      ) {
        uncontrolledValid.value = false;
        uncontrolledError.value = true;
      }
    });

    const onSubmit = () => {
      context.emit("submit");
    };

    return {
      promotionCode,
      onInput,
      onSubmit,
      integratedValid,
      integratedError,
    };
  },
});
