












import DownArrow from "@/assets/ElementsImage/Arrow-Down.vue";
import UpArrow from "@/assets/ElementsImage/Arrow-Up.vue";
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    isShowMenu: { type: Boolean, required: true },
    color: { type: String },
    size: { type: String },
  },
  components: {
    DownArrow,
    UpArrow,
  },
});
