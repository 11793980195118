








































import { defineComponent, computed } from "@vue/composition-api";

import { ProductDetail } from "@/components/Product/Types";

import TwitterLogo from "@/assets/Logo/TwitterLogoCircle.vue";
import FacebookLogo from "@/assets/Logo/FacebookLogoCircle.vue";
import KakaotalkLogo from "@/assets/Logo/KakaotalkLogoCircle.vue";
import LinkIcon from "@/assets/ElementsImage/LinkIcon.vue";

export default defineComponent({
  components: {
    TwitterLogo,
    FacebookLogo,
    KakaotalkLogo,
    LinkIcon,
  },
  emits: ["close", "click:copy"],
  props: {
    productDetail: {
      type: Object as () => ProductDetail,
      required: true,
    },
  },
  setup(props, context) {
    const currentUrl = window.location.href;

    const discountRate = computed(() => {
      if (!props.productDetail) return 0;

      const rate = Math.round(
        ((props.productDetail.marketPrice - props.productDetail.price) /
          props.productDetail.marketPrice) *
          100
      );

      return rate;
    });

    const shareTwitterHandler = () => {
      const twitterShareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}`;

      window.open(twitterShareUrl, "_blank");
      context.emit("close");
    };

    const shareFacebookHandler = () => {
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`;

      window.open(facebookShareUrl, "_blank");
      context.emit("close");
    };

    const shareKakaotalkHandler = () => {
      try {
        window.Kakao.Link.sendDefault({
          objectType: "commerce",
          content: {
            title: props.productDetail.description,
            imageUrl: props.productDetail.thumbnailImg,
            link: {
              mobileWebUrl: currentUrl,
              webUrl: currentUrl,
            },
          },
          commerce: {
            productName: props.productDetail.productName,
            regularPrice: props.productDetail.marketPrice,
            discountRate: discountRate.value,
            discountPrice: props.productDetail.price,
          },
          buttons: [
            {
              title: "자세히 보기",
              link: {
                mobileWebUrl: currentUrl,
                webUrl: currentUrl,
              },
            },
          ],
        });
        context.emit("close");
      } catch {
        alert("알 수 없는 이유로 공유에 실패했습니다.");
      }
    };

    const copyUrlHandler = async () => {
      try {
        await context.root.$copyText(currentUrl);
        context.emit("click:copy");
      } catch {
        alert("복사 실패");
      }
    };

    return {
      shareTwitterHandler,
      shareFacebookHandler,
      shareKakaotalkHandler,
      copyUrlHandler,
    };
  },
});
