












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [String, Number],
      default: 10,
    },
    height: {
      type: [String, Number],
      default: 2,
    },
    color: {
      type: String,
      default: "#495057",
    },
  },
});
