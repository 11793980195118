















































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
  watch,
  watchEffect,
  computed,
  onUnmounted,
} from "@vue/composition-api";
import { useIntersectionObserver } from "@vueuse/core";
import { cloneDeep } from "lodash";

// types
import { Shop } from "@/components/Shop/Types";
import {
  SelectedProduct as ISelectedProduct,
  Option,
} from "@/components/Product/Types";

import useProductDetail from "@/components/Product/Hooks/useProductDetail";
import useSelectedProducts from "@/components/Product/Hooks/useSelectedProducts";
import useCart from "@/components/Product/Hooks/useCart";
import useChannelTalk from "@/components/ChannelTalk/Hooks/useChannelTalk";
import useProductTimer from "@/components/Product/Hooks/useProductTimer";
import { useI18n } from "@/libs/i18n";
import { useRouter } from "@/libs/router";
import useBaseOptions from "@/components/Product/Hooks/useBaseOptions";
import formatNumber from "@/components/Hooks/formatNumber";
import { getPromotionCode } from "@/api/product";

import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import ProductDetail from "@/components/Product/Detail/ProductDetail.vue";
import ProductOptionSelect from "@/components/Product/Detail/ProductOptionSelect.vue";
import BaseHeader from "@/components/Layout/BaseHeader.vue";
import BuyButtonGroup from "@/components/Product/Detail/BuyButtonGroup.vue";
import ScrollTopButton from "@/components/Common/ScrollTopButton.vue";
import SocialSharing from "@/components/Product/Detail/SocialSharing.vue";
import AutoDestroyPopup from "@/components/Common/AutoDestroyPopup.vue";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";
import BaseFooter from "@/components/Layout/BaseFooter.vue";
import SelectMenu from "@/components/Product/Detail/SelectMenu.vue";
import SelectedOptionControlBox from "@/components/Product/Detail/SelectedOptionControlBox.vue";
import PromotionCodeModal from "@/components/Product/Detail/PromotionCodeModal.vue";

export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
    ProductDetail,
    ProductOptionSelect,
    BaseHeader,
    BuyButtonGroup,
    ScrollTopButton,
    SocialSharing,
    AutoDestroyPopup,
    ChannelTalk,
    BaseFooter,
    SelectMenu,
    SelectedOptionControlBox,
    PromotionCodeModal,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
    productNo: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const store = context.root.$store;
    const { t } = useI18n();
    const router = useRouter();

    const { productDetail } = useProductDetail(
      props.shopInfo.urlPath,
      props.productNo
    );
    const promotionCode = ref<string | null>(null);
    const isPromotionCodeRequired = computed(() => promotionCode.value != null);
    getPromotionCode(props.productNo)
      .then((response) => {
        const {
          data: { code },
        } = response;

        promotionCode.value = code;
      })
      .catch(() => {
        alert("예기치 못한 오류가 발생했습니다.");
      });

    const {
      selectedProducts,
      selectStep,
      selectProduct,
      deleteProduct,
      changeProductQuantity,
      totalQuantity,
      totalPrice,
    } = useSelectedProducts(productDetail);

    const { showChannelButton, hideChannelButton } = useChannelTalk();

    watchEffect(() => {
      if (selectStep.value === "VIEW") {
        showChannelButton();
      } else {
        hideChannelButton();
      }
    });

    onUnmounted(() => showChannelButton());

    const { isCartMessageVisible, addCart } = useCart();

    const clickCartBtnHandler = () => {
      if (selectedProducts.value.length === 0) {
        return;
      }

      addCart(props.shopInfo.idx, selectedProducts.value);
    };

    // const checkSameAdditionalOption = (
    //   cartAdditionalOptions: SelectedOption[],
    //   targetAdditionalOptions: SelectedOption[]
    // ) => {
    //   // 답변 개수가 다른 경우
    //   const isSameLength =
    //     cartAdditionalOptions.length === targetAdditionalOptions.length;
    //   if (!isSameLength) return false;

    //   // 답변 개수 같은 경우
    //   for (const targetOption of targetAdditionalOptions) {
    //     // 카트에 담긴 옵션과 다른 경우
    //     const isInclude = cartAdditionalOptions.some(
    //       (cartOption) => cartOption.optionIdx === targetOption.optionIdx
    //     );
    //     if (!isInclude) return false;
    //   }

    //   // 답변 개수 같고, 담긴 옵션과 같은 경우
    //   return true;
    // };

    // const filterSameProduct = (
    //   cartProducts: ISelectedProduct[],
    //   targetProduct: ISelectedProduct
    // ) => {
    //   const targetBaseOptions = targetProduct.options.baseOptions;
    //   const targetAdditionalOptions = targetProduct.options.additionalOptions;
    //   const targetHasTextOption =
    //     targetBaseOptions.some((option) => option.type === "TEXT") ||
    //     targetAdditionalOptions.some((option) => option.type === "TEXT");

    //   // 등록할 제품에 text형 선택지 있으면 다른 상품 취급
    //   if (targetHasTextOption) return null;

    //   const sameProductIndex = cartProducts.findIndex((cartProduct) => {
    //     // 같은 제품 번호 인지
    //     const isSameProductNo =
    //       cartProduct.productNo === targetProduct.productNo;

    //     // 최하위 베이스 옵션이 같은지
    //     const targetLastBaseOptionIdx =
    //       targetBaseOptions.length > 0
    //         ? targetBaseOptions[targetBaseOptions.length - 1].optionIdx
    //         : null;
    //     const cartBaseOptions = cartProduct.options.baseOptions;
    //     const cartLastBaseOptionIdx =
    //       cartBaseOptions.length > 0
    //         ? cartBaseOptions[cartBaseOptions.length - 1].optionIdx
    //         : null;
    //     const isSameLastBaseOption =
    //       targetLastBaseOptionIdx === cartLastBaseOptionIdx;

    //     // additionalOptions 같은지
    //     const cartAdditionalOptions = cartProduct.options.additionalOptions;
    //     const isSameAdditionalOption = checkSameAdditionalOption(
    //       cartAdditionalOptions,
    //       targetAdditionalOptions
    //     );

    //     return (
    //       isSameProductNo && isSameLastBaseOption && isSameAdditionalOption
    //     );
    //   });

    //   // 카트에 같은 상품 있으면 새로 담는 제품으로 교체
    //   if (sameProductIndex > -1) {
    //     cartProducts.splice(sameProductIndex, 1, targetProduct);
    //     return cartProducts;
    //   } else {
    //     return null;
    //   }
    // };
    const isPromotionCodeModalOpened = ref(false);

    const clickBuyBtnHandler = () => {
      if (productDetail.value?.stock === 0) {
        return;
      } else if (isPromotionCodeRequired.value) {
        isPromotionCodeModalOpened.value = true;
      } else if (
        productDetail.value?.options.categories.length === 0 &&
        selectedProducts.value.length === 0
      ) {
        selectProduct([]);
      } else {
        selectStep.value = "MENU";
      }
    };

    const onSubmitPromotionCode = () => {
      if (
        productDetail.value?.options.categories.length === 0 &&
        selectedProducts.value.length === 0
      ) {
        selectProduct([]);
      } else {
        selectStep.value = "MENU";
      }

      isPromotionCodeModalOpened.value = false;
    };

    const moveToOrderPage = (
      shopUrl: string,
      productList: ISelectedProduct[]
    ) => {
      if (productList.length < 1) {
        alert("상품을 선택하지 않았습니다. 상품을 선택해주세요");
        return;
      }

      const shopIdx = props.shopInfo.idx;
      const orderProductsObj: { [shopIdx: number]: ISelectedProduct[] } = {};
      orderProductsObj[shopIdx] = productList;

      store.commit("setOrderProducts", orderProductsObj);
      store.commit("deleteSelectedCartIndices", {
        shopIdx: props.shopInfo.idx,
      });

      router.push({
        name: "OrderPage",
        params: {
          shopUrl: shopUrl,
        },
      });
    };

    const clickBuyNowBtnHandler = () => {
      if (selectedProducts.value.length === 0) {
        return;
      }

      moveToOrderPage(props.shopInfo.urlPath, selectedProducts.value);
    };

    const myshopProductScrollEl = ref();
    const isScrollTopBtnVisible = ref(false);

    const scrollTopHandler = () => {
      myshopProductScrollEl.value.scrollTo({
        top: 0,
      });
      document.documentElement.scrollTo({
        top: 0,
      });
    };

    const scrollHandler = () => {
      if (myshopProductScrollEl.value.scrollTop >= 400) {
        isScrollTopBtnVisible.value = true;
      } else {
        isScrollTopBtnVisible.value = false;
      }
    };

    const globalScrollHandler = () => {
      const documentEl = document.documentElement;

      if (documentEl.scrollTop >= 400) {
        isScrollTopBtnVisible.value = true;
      } else {
        isScrollTopBtnVisible.value = false;
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", globalScrollHandler);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", globalScrollHandler);
      document.body.classList.remove("modal-open");
    });

    const isSocialShareOpened = ref(false);

    const isCopyMessageVisible = ref(false);

    const copyLinkHandler = () => {
      isSocialShareOpened.value = false;
      isCopyMessageVisible.value = true;
    };

    const goBackHandler = () => {
      router.push({
        name: "Myshop",
        params: {
          shopUrl: props.shopInfo.urlPath,
        },
      });
    };

    const isScrollAvailable = ref(true);

    watch(
      () => [isSocialShareOpened.value, selectStep.value],
      (newValue) => {
        if (newValue[0] || selectStep.value !== "VIEW") {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );

    const { duration: startDateDuration, formattedDuration: startDateTimer } =
      useProductTimer(computed(() => productDetail.value?.startAt));

    const { duration: endDateDuration, formattedDuration: endDateTimer } =
      useProductTimer(computed(() => productDetail.value?.endAt));

    const isSoldOut = computed(() => {
      return (
        productDetail.value &&
        (productDetail.value.stock === 0 ||
          productDetail.value.state === "SOLDOUT" ||
          productDetail.value.state === "INACTIVE" ||
          (productDetail.value.endAt != null && endDateDuration.value <= 0))
      );
    });

    const productDetailEl = ref();
    const productDetailTimerEl = computed(
      () => productDetailEl.value?.$refs.timerEl
    );
    const isProductDetailTimerVisible = ref(false);

    useIntersectionObserver(productDetailTimerEl, ([{ isIntersecting }]) => {
      isProductDetailTimerVisible.value = isIntersecting;
    });

    const baseOptions = computed<{
      optionType: "SINGLE" | "COMB";
      categories: string[];
      details: Option[][];
    }>(() => {
      return (
        productDetail.value?.options ?? {
          optionType: "SINGLE",
          categories: [],
          details: [],
        }
      );
    });

    const {
      selectedOptions: selectedBaseOptions,
      optionValues: selectedBaseOptionValues,
      currentOptions: currentBaseOptions,
      setCurrentOptionDepth: setCurrentBaseOptionDepth,
      currentCategory: currentBaseOptionCategory,
      setSelectedBaseOptions,
      resetSelectedOptions: resetSelectedBaseOptions,
      currentOptionDepth: currentBaseOptionDepth,
    } = useBaseOptions(baseOptions);

    const openBaseOptionMenu = (idx: number) => {
      if (!productDetail.value) {
        return;
      }

      try {
        setCurrentBaseOptionDepth(idx);
        selectStep.value = "BASE";
      } catch {
        alert(t("NOT_SELECT_PREV_OPTION_ERROR_MESSAGE"));
      }
    };

    const closeBaseOptionMenu = () => {
      setCurrentBaseOptionDepth(-1);
      selectStep.value = "MENU";
    };

    const onSelectBaseOption = (idx: number) => {
      if (!productDetail.value) {
        return;
      }

      setSelectedBaseOptions(idx);
      selectStep.value = "MENU";
    };

    watch(
      () => selectedBaseOptions.value,
      (newValue) => {
        if (newValue.length <= 0 || newValue.includes(null)) {
          return;
        }

        try {
          selectProduct(cloneDeep(newValue) as Option[]);
          resetSelectedBaseOptions();
        } catch (error) {
          alert(t("error.sameOption"));
        }
      }
    );

    return {
      productDetail,
      baseOptions,
      selectedProducts,
      myshopProductScrollEl,
      isSocialShareOpened,
      isCopyMessageVisible,
      isCartMessageVisible,
      isScrollTopBtnVisible,
      isScrollAvailable,
      selectStep,
      startDateDuration,
      startDateTimer,
      endDateDuration,
      endDateTimer,
      isSoldOut,
      productDetailEl,
      isProductDetailTimerVisible,
      currentBaseOptions,
      currentBaseOptionCategory,
      selectedBaseOptionValues,
      selectedBaseOptions,
      currentBaseOptionDepth,
      totalQuantity,
      totalPrice,
      isPromotionCodeModalOpened,
      promotionCode,

      clickBuyBtnHandler,
      clickCartBtnHandler,
      selectProduct,
      moveToOrderPage,
      scrollTopHandler,
      copyLinkHandler,
      goBackHandler,
      scrollHandler,
      deleteProduct,
      changeProductQuantity,
      clickBuyNowBtnHandler,
      onSelectBaseOption,
      openBaseOptionMenu,
      closeBaseOptionMenu,
      formatNumber,
      onSubmitPromotionCode,
    };
  },
});
