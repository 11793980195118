










import { defineComponent, watch } from "@vue/composition-api";

export default defineComponent({
  props: {
    duration: {
      type: Number,
      default: 1000,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["destroy"],
  setup(props, context) {
    watch(
      () => props.show,
      () => {
        if (props.show) {
          setTimeout(() => {
            context.emit("destroy");
          }, props.duration);
        }
      }
    );
  },
});
