import moment, { Moment } from "moment";
import {
  unref,
  ref,
  computed,
  watchEffect,
  onBeforeUnmount,
  watch,
} from "@vue/composition-api";

import { useI18n } from "@/libs/i18n";

import type { MaybeRef } from "@vueuse/core";

export type FlexibleDate = Moment | Date | string;

export default (date: MaybeRef<FlexibleDate | undefined | null>) => {
  const { t } = useI18n();

  const targetTime = ref<string>();
  const currentTime = ref<string>();
  const intervalId = ref<ReturnType<typeof setInterval>>();
  const duration = ref(0);

  const reset = (date: MaybeRef<FlexibleDate | undefined | null>) => {
    intervalId.value && clearInterval(intervalId.value);

    const targetMoment = moment(unref(date) ?? undefined);
    const currentMoment = moment();

    targetTime.value = targetMoment.utc().format();
    currentTime.value = currentMoment.utc().format();
    duration.value = Math.max(
      0,
      moment.duration(targetMoment.diff(currentMoment)).asMilliseconds()
    );

    if (duration.value > 0) {
      intervalId.value = setInterval(() => {
        duration.value = Math.max(0, duration.value - 100);
      }, 100);
    }
  };

  reset(date);

  const formattedDuration = computed(() => {
    const mDuration = moment.duration(duration.value);

    const day = Math.floor(mDuration.asDays());
    const hour = mDuration.hours().toString().padStart(2, "0");
    const minute = mDuration.minutes().toString().padStart(2, "0");
    const second = mDuration.seconds().toString().padStart(2, "0");
    const millisecond = mDuration.milliseconds().toString();

    if (day > 0) {
      return `${day}${t("UNIT_DAY")} ${hour}:${minute}:${second}`;
    }

    return `${hour}:${minute}:${second}.${millisecond[0]}`;
  });

  onBeforeUnmount(() => {
    if (intervalId.value != null) {
      clearInterval(intervalId.value);
    }
  });

  watchEffect(() => {
    if (duration.value === 0 && intervalId.value != null) {
      clearInterval(intervalId.value);
    }
  });

  watch(
    () => unref(date),
    (newDate) => {
      reset(newDate);
    }
  );

  return {
    duration,
    formattedDuration,
    reset,
  };
};
