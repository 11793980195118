





















import { defineComponent, ref, computed } from "@vue/composition-api";

import UpdownArrow from "@/components/Common/UpDownArrow.vue";

export default defineComponent({
  components: { UpdownArrow },
  props: {
    title: {
      type: String,
      require: true,
    },
    detail: {
      type: String,
      default: "",
    },
  },
  setup() {
    const isOpened = ref(false);
    const moreBtnText = computed(() => {
      return isOpened.value ? "펼치기" : "접기";
    });

    return {
      isOpened,
      moreBtnText,
    };
  },
});
