

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    width: {
      type: [String, Number],
      default: 14,
    },
    height: {
      type: [String, Number],
      default: 19,
    },
    color: {
      type: String,
      default: "#545454",
    },
  },
});
