



































































































































































































import { defineComponent, ref, computed } from "@vue/composition-api";

import formatNumber from "@/components/Hooks/formatNumber";
import useProductTimer from "@/components/Product/Hooks/useProductTimer";

import { ProductDetail } from "@/components/Product/Types";

import UpdownArrow from "@/components/Common/UpDownArrow.vue";
import DetailCollapsible from "@/components/Product/Detail/DetailCollapsible.vue";

export default defineComponent({
  components: { UpdownArrow, DetailCollapsible },
  props: {
    productDetail: {
      type: Object as () => ProductDetail,
      required: true,
    },
  },
  setup(props) {
    const formattedPrice = formatNumber(props.productDetail.price);
    const formattedMarketPrice = formatNumber(props.productDetail.marketPrice);
    const discountRate = Math.round(
      ((props.productDetail.marketPrice - props.productDetail.price) /
        props.productDetail.marketPrice) *
        100
    );

    const isDetailInfoOpened = ref(false);
    const moreBtnText = computed(() => {
      return isDetailInfoOpened.value ? "접기" : "더보기";
    });

    const isDeliveryInfoOpened = ref(false);
    const deliveryInfoMoreBtnText = computed(() => {
      return isDeliveryInfoOpened.value ? "닫기" : "펼치기";
    });

    const hasDeliveryFreeCond = computed(() => {
      if (
        props.productDetail.shippingFreeCond != null &&
        props.productDetail.shippingFreeCond.gte != null
      ) {
        return true;
      } else {
        return false;
      }
    });

    const hasAdditionalShippingFee = computed(() => {
      return Object.values(props.productDetail.additionalShippingFeeCond).some(
        (value) => value > 0
      );
    });

    const { formattedDuration: startDateTimer, duration: startDateDuration } =
      useProductTimer(props.productDetail.startAt);
    const { formattedDuration: endDateTimer, duration: endDateDuration } =
      useProductTimer(props.productDetail.endAt);

    const isSoldOut = computed(() => {
      return (
        props.productDetail.stock === 0 ||
        props.productDetail.state === "SOLDOUT" ||
        props.productDetail.state === "INACTIVE" ||
        (props.productDetail.endAt != null && endDateDuration.value <= 0)
      );
    });

    const additionalShippingFeeText = computed(() => {
      const { jeju, islandMountain } =
        props.productDetail.additionalShippingFeeCond;

      if (jeju === 0 && islandMountain === 0) {
        return "";
      }

      const additionalFeeTextList = [
        jeju > 0 ? `제주 ${formatNumber(jeju)}원` : null,
        islandMountain > 0
          ? `도서산간 ${formatNumber(islandMountain)}원`
          : null,
      ];

      const _additionalShippingFeeText = additionalFeeTextList
        .filter((value) => value != null)
        .join(", ");

      return `(${_additionalShippingFeeText})`;
    });

    const timerEl = ref();

    return {
      formattedPrice,
      formattedMarketPrice,
      discountRate,
      isDetailInfoOpened,
      moreBtnText,
      isDeliveryInfoOpened,
      deliveryInfoMoreBtnText,
      hasDeliveryFreeCond,
      isSoldOut,
      additionalShippingFeeText,
      startDateTimer,
      startDateDuration,
      endDateTimer,
      endDateDuration,
      timerEl,
      hasAdditionalShippingFee,

      formatNumber,
    };
  },
});
