






























import { defineComponent } from "@vue/composition-api";

import PlusIcon from "@/assets/ElementsImage/PlusIcon.vue";
import MinusIcon from "@/assets/ElementsImage/MinusIcon.vue";

export default defineComponent({
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: Number,
    max: Number,
  },
  emits: ["input", "error"],
  setup(props, context) {
    const convertIconColor = (active: boolean) => {
      const ICON_COLOR = {
        active: "#495057",
        inactive: "#CED4DA",
      };

      if (active) {
        return ICON_COLOR.active;
      } else {
        return ICON_COLOR.inactive;
      }
    };

    const input = (event: any) => {
      if (event.target.value === "") {
        return;
      }

      const newValue = Number(event.target.value);

      if (
        (props.min != null && newValue < props.min) ||
        (props.max != null && newValue > props.max)
      ) {
        context.emit("error");
        return;
      }

      context.emit("input", newValue);
    };

    return {
      convertIconColor,
      input,
    };
  },
});
