





































































































import { defineComponent, computed } from "@vue/composition-api";

import { Option } from "@/components/Product/Types";

import { useI18n } from "@/libs/i18n";

import LeftArrowIcon from "@/assets/ElementsImage/LeftArrowIcon.vue";
import CloseIcon from "@/assets/ElementsImage/CloseIcon.vue";
import OptionSelectButton from "@/components/Product/Detail/OptionSelectButton.vue";
import MainTextInput from "@/components/Common/MainTextInput.vue";

export default defineComponent({
  components: {
    LeftArrowIcon,
    CloseIcon,
    OptionSelectButton,
    MainTextInput,
  },
  props: {
    options: {
      type: Array as () => Option[],
      required: true,
    },
    category: {
      type: String,
    },
  },
  emits: ["close", "select"],
  setup(props, context) {
    const { t } = useI18n();

    const closeOptionHandler = () => {
      context.emit("close");
    };

    const selectOptionHandler = (idx: number) => {
      if (idx < 0 || idx >= props.options.length) {
        return;
      }

      // TODO: 주관식 옵션 기획 필요
      if (props.options[idx].type === "TEXT") {
        return;
      }

      context.emit("select", idx);
    };

    const categoryTitle = computed(() => {
      return props.category ?? t("OPTION");
    });

    return {
      categoryTitle,

      closeOptionHandler,
      selectOptionHandler,
    };
  },
});
