







import { defineComponent } from "@vue/composition-api";

import UpArrowRoundIcon from "@/assets/ElementsImage/UpArrowRoundIcon.vue";

export default defineComponent({
  components: { UpArrowRoundIcon },
  emits: ["scroll:top"],
  setup(props, context) {
    const scrollTopHandler = () => {
      context.emit("scroll:top");
    };

    return {
      scrollTopHandler,
    };
  },
});
