import { computed, ref, unref, watch } from "@vue/composition-api";
import { cloneDeep } from "lodash";

import type { MaybeRef } from "@vueuse/core";
import type { Option } from "@/components/Product/Types";

export type Props = MaybeRef<{
  optionType: "COMB" | "SINGLE";
  categories: string[];
  details: Option[][];
}>;

export default (props: Props) => {
  const baseOptions = computed(() => unref(props));
  const baseOptionDepth = computed(() => baseOptions.value.categories.length);

  const selectedOptions = ref<(Option | null)[]>(
    Array(baseOptionDepth.value).fill(null)
  );

  const resetSelectedOptions = () => {
    selectedOptions.value = Array(baseOptionDepth.value).fill(null);
  };

  const optionValues = computed(() => {
    return selectedOptions.value.map((option) => option?.name ?? "");
  });
  const currentOptionDepth = ref(-1);

  const setCurrentOptionDepth = (idx: number) => {
    if (idx < -1 || idx >= baseOptions.value.categories.length) {
      return;
    }

    if (idx <= 0) {
      currentOptionDepth.value = idx;
    } else if (baseOptions.value.optionType === "COMB") {
      const hasPrevOptionNotSelected = selectedOptions.value
        .slice(0, idx)
        .some((option) => option == null);

      if (hasPrevOptionNotSelected) {
        throw new Error("OptionSelectionOrderError");
      }

      currentOptionDepth.value = idx;
    } else {
      currentOptionDepth.value = idx;
    }
  };

  const currentOptions = computed(() => {
    if (currentOptionDepth.value < 0) {
      return [];
    } else if (currentOptionDepth.value === 0) {
      return baseOptions.value.details[0];
    }

    if (baseOptions.value.optionType === "SINGLE") {
      return baseOptions.value.details[currentOptionDepth.value];
    } else {
      return (
        selectedOptions.value[currentOptionDepth.value - 1]?.childOptions ?? []
      );
    }
  });

  const setSelectedBaseOptions = (idx: number) => {
    if (
      currentOptionDepth.value < 0 ||
      currentOptionDepth.value >= baseOptionDepth.value ||
      idx < 0 ||
      idx >= currentOptions.value.length
    ) {
      return;
    }

    const selectedOption = currentOptions.value[idx];

    if (baseOptions.value.optionType === "COMB") {
      const restCount =
        selectedOptions.value.length - currentOptionDepth.value - 1;
      selectedOptions.value = [
        ...selectedOptions.value.slice(0, currentOptionDepth.value),
        cloneDeep(selectedOption),
        ...Array(restCount).fill(null),
      ];
    } else {
      selectedOptions.value = [
        ...selectedOptions.value.slice(0, currentOptionDepth.value),
        cloneDeep(selectedOption),
        ...selectedOptions.value.slice(currentOptionDepth.value + 1),
      ];
    }

    currentOptionDepth.value = -1;
  };

  const currentCategory = computed(() => {
    if (currentOptionDepth.value < 0) {
      return "";
    }

    return baseOptions.value.categories[currentOptionDepth.value] ?? "";
  });

  watch(
    () => baseOptionDepth.value,
    (newValue) => {
      selectedOptions.value = Array(newValue).fill(null);
    }
  );

  return {
    selectedOptions,
    optionValues,
    currentOptionDepth,
    baseOptionDepth,
    setSelectedBaseOptions,
    currentOptions,
    setCurrentOptionDepth,
    currentCategory,
    resetSelectedOptions,
  };
};
