import axios from "axios";
import camelcaseKeys from "camelcase-keys";

import type { Product } from "@/components/Product/Types";

export const getDetail = async (storeUrl: string, productNo: string) => {
  const res = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/product/v2/detail/shp/`,
    {
      params: { url_path: storeUrl, product_no: productNo },
    }
  );

  const data = camelcaseKeys(res.data.data, {
    deep: true,
    stopPaths: ["options.options"],
  });

  const shippingFreeCond = camelcaseKeys(JSON.parse(data.shippingFreeCond), {
    deep: true,
  });

  const additionalShippingFeeCond = camelcaseKeys(
    JSON.parse(data.additionalShippingFeeCond),
    { deep: true }
  );

  const optionDetails = Object.entries(data.options.options).reduce(
    (acc: any, cur) => {
      return [...acc, camelcaseKeys(cur[1] as any, { deep: true })];
    },
    []
  );

  const formattedData = {
    productNo: data.tagbyProductNo,
    categoryIdx: data.categoryIdx,
    brandName: data.brandName,
    productName: data.name,
    price: data.price,
    thumbnailImg: data.thumbnailImgUrl,
    marketPrice: data.marketPrice,
    stock: data.stock,
    state: data.state,
    startAt: data.startAt,
    endAt: data.endAt,
    sellType: data.sellType,
    originInfoJson: data.originInfoJson,
    detailHtmlContent: data.detailHtmlContent,
    description: data.description,
    deliveryContent: data.deliveryContent,
    refundContent: data.refundContent,
    buyLimitCond: data.buyLimitCond,
    shippingFeeType: data.shippingFee,
    shippingFee: data.shippingFee,
    infFee: data.infFee,
    options: {
      optionType: data.options.optionType,
      categories: data.options.categories,
      details: optionDetails,
    },
    minDeliveryDays: data.minDeliveryDays,
    maxDeliveryDays: data.maxDeliveryDays,
    shippingFreeCond,
    additionalShippingFeeCond,
    isAbroad: data.isAbroad,
  };

  return formattedData;
};

export const getList = async (
  storeUrl: string,
  config: { page?: number; productNameOrBrand?: string } = {}
): Promise<{
  count: number;
  next: string;
  previous: string;
  results: Product[];
}> => {
  const { page, productNameOrBrand } = config;

  const res = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/myshop/product/v2/member/`,
    {
      params: { url_path: storeUrl, page, text: productNameOrBrand },
    }
  );

  const data = camelcaseKeys(res.data.data, { deep: true });

  const formattedResults = data.results.map((result: any) => {
    return {
      ...result,
      isAbroad: result.isAbroad,
    };
  });

  return {
    ...data,
    results: formattedResults,
  };
};

export const getPromotionCode = async (tagbyProductNo: string) => {
  const response = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/product/discount-code/`,
    {
      params: {
        product_no: tagbyProductNo,
      },
    }
  );

  const data = response.data;

  return data;
};
