






















import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  emits: ["click:buy", "click:share"],
  props: {
    soldOut: { type: Boolean, default: false },
  },
  setup(props, context) {
    const clickShareBtnHandler = () => {
      context.emit("click:share");
    };

    const clickBuyBtnHandler = () => {
      if (props.soldOut) {
        alert("품절된 상품입니다.");
        return;
      }
      context.emit("click:buy");
    };

    const buyBtnText = computed(() => {
      const text = props.soldOut ? "품절" : "구매하기";

      return text;
    });

    return {
      buyBtnText,
      clickShareBtnHandler,
      clickBuyBtnHandler,
    };
  },
});
