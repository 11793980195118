






























import { computed, defineComponent } from "@vue/composition-api";

import formatNumber from "@/components/Hooks/formatNumber";

import { SelectedOption } from "@/components/Product/Types";

import CloseIcon from "@/assets/ElementsImage/CloseIcon.vue";
import MainCountInput from "@/components/Common/MainCountInput.vue";

export default defineComponent({
  components: {
    CloseIcon,
    MainCountInput,
  },
  props: {
    productName: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    options: {
      type: Array as () => SelectedOption[],
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["delete", "change:quantity"],
  setup(props) {
    const min = 1;
    const raiseCountError = () => {
      alert(`수량은 ${min} 이상 선택해야합니다.`);
    };

    const label = computed(() => {
      if (props.options.length === 0) {
        return props.productName;
      }

      const label = props.options.reduce((acc, option) => {
        if (acc === "") {
          return option.name;
        }

        return acc + ` + ${option.name}`;
      }, "");

      return label;
    });

    const totalPrice = computed(() => {
      const optionsSum = props.options.reduce(
        (acc, option) => acc + option.addPrice,
        0
      );
      const total = (props.price + optionsSum) * props.quantity;

      return total;
    });

    return {
      label,
      min,
      totalPrice,

      raiseCountError,
      formatNumber,
    };
  },
});
