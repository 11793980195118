var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-detail"},[_c('div',{staticClass:"product-detail__thumbnail-container"},[_c('img',{staticClass:"product-detail__thumbnail-img",attrs:{"src":_vm.productDetail.thumbnailImg,"alt":""}}),(_vm.isSoldOut)?_c('div',{staticClass:"product-detail__sold-out-message"},[_vm._v("품절")]):(_vm.productDetail.startAt != null && _vm.startDateDuration > 0)?_c('div',{ref:"timerEl",staticClass:"product-detail__timer-container"},[_c('div',{staticClass:"product-detail__timer"},[_c('span',{staticClass:"product-detail__timer-type"},[_vm._v(_vm._s(_vm.$t("SCHEDULE_TO_OPEN")))]),_vm._v(" "+_vm._s(_vm.startDateTimer)+" ")])]):(
        _vm.productDetail.sellType === 'RECRUITMENT' &&
        _vm.productDetail.endAt != null &&
        _vm.endDateDuration > 0
      )?_c('div',{ref:"timerEl",staticClass:"product-detail__timer-container"},[_c('div',{staticClass:"product-detail__timer product-detail__timer--special"},[_c('span',{staticClass:"product-detail__timer-type product-detail__timer-type--special"},[_vm._v(_vm._s(_vm.$t("REMAIN_TIME")))]),_vm._v(" "+_vm._s(_vm.endDateTimer)+" ")])]):_vm._e()]),_c('div',{staticClass:"product-detail__simple-info"},[_c('div',{staticClass:"product-detail__brand-name",class:{
        'product-detail__brand-name--sold-out': _vm.isSoldOut,
      }},[_vm._v(" "+_vm._s(_vm.productDetail.brandName)+" ")]),_c('div',{staticClass:"product-detail__product-name",class:{
        'product-detail__product-name--sold-out': _vm.isSoldOut,
      }},[_vm._v(" "+_vm._s(_vm.productDetail.productName)+" ")]),_c('div',{staticClass:"product-detail__price-title",class:{
        'product-detail__price-title--sold-out': _vm.isSoldOut,
      }},[_vm._v(" 판매가격 ")]),_c('div',{staticClass:"product-detail__price-container"},[_c('div',{staticClass:"product-detail__price",class:{
          'product-detail__price--sold-out': _vm.isSoldOut,
        }},[_c('span',{staticClass:"product-detail__price-num"},[_vm._v(_vm._s(_vm.formattedPrice))]),_c('span',{staticClass:"product-detail__price-unit"},[_vm._v("원")])]),_c('div',{staticClass:"product-detail__market-price-container"},[_c('span',{staticClass:"product-detail__market-price",class:{
            'product-detail__market-price--sold-out': _vm.isSoldOut,
          }},[_vm._v(_vm._s(_vm.formattedMarketPrice))]),_c('span',{staticClass:"product-detail__discount-rate",class:{
            'product-detail__discount-rate--sold-out': _vm.isSoldOut,
          }},[_vm._v(_vm._s(_vm.discountRate)+"%")])])])]),_c('div',{staticClass:"product-detail__delivery-fee-info"},[_c('div',{staticClass:"product-detail__delivery-fee-message-wrap"},[(_vm.hasDeliveryFreeCond)?_c('p',{staticClass:"product-detail__delivery-free-cond"},[_c('span',{staticClass:"product-detail__delivery-free-text"},[_vm._v("FREE")]),_vm._v(" *"+_vm._s(_vm.formatNumber(_vm.productDetail.shippingFreeCond.gte))+" 원 이상 구매시 무료 배송 ")]):_vm._e(),(_vm.productDetail.shippingFee === 0)?_c('p',{staticClass:"product-detail__delivery-fee-message"},[_vm._v(" 무료배송 "+_vm._s(_vm.hasAdditionalShippingFee ? _vm.additionalShippingFeeText : "")+" ")]):_c('p',{staticClass:"product-detail__delivery-fee-message"},[_vm._v(" 배송비 "),_c('span',[_vm._v(_vm._s(_vm.formatNumber(_vm.productDetail.shippingFee)))]),_vm._v("원 "+_vm._s(_vm.hasAdditionalShippingFee ? _vm.additionalShippingFeeText : "")+" ")]),_c('p',{staticClass:"product-detail__delivery-fee-message product-detail__delivery-fee-message--dimmed"},[_vm._v(" 입금 확인 후 "+_vm._s(_vm.productDetail.minDeliveryDays)+"-"+_vm._s(_vm.productDetail.maxDeliveryDays)+"일 후 출고 예정 ")])])]),(_vm.productDetail.isAbroad)?_c('div',{staticClass:"product-detail__abroad-container"},[_vm._m(0),_c('strong',{staticClass:"product-detail__abroad-title"},[_vm._v(_vm._s("해외직배송 상품안내"))]),_c('p',{staticClass:"product-detail__abroad-description",domProps:{"textContent":_vm._s(
        '이 상품은 국내로 배송되는 상품으로 배송,반품,교환이\n일반적인 국내 배송 상품과 다를 수 있습니다.\n자세한 내용은 교환/환불 정보에서 확인해 주세요.'
      )}})]):_vm._e(),_vm._m(1),_c('div',{staticClass:"product-detail__detail-info"},[_c('div',{staticClass:"product-detail__detail-info-wrap"},[_c('div',{staticClass:"product-detail__detail-info-content",class:{
          'product-detail__detail-info-content--opened': _vm.isDetailInfoOpened,
        },domProps:{"innerHTML":_vm._s(_vm.productDetail.detailHtmlContent)}}),_c('button',{staticClass:"product-detail__detail-info-more-btn",class:{
          'product-detail__detail-info-more-btn--opened': _vm.isDetailInfoOpened,
        },attrs:{"type":"button"},on:{"click":function($event){_vm.isDetailInfoOpened = !_vm.isDetailInfoOpened}}},[_vm._v(" 상품 정보 "+_vm._s(_vm.moreBtnText)+" "),_c('UpdownArrow',{staticClass:"margin-left-4",attrs:{"color":"#0064e6","size":"24","isShowMenu":_vm.isDetailInfoOpened}})],1)])]),_c('div',{staticClass:"product-detail__product-info"},[_c('strong',{staticClass:"product-detail__product-info-title"},[_vm._v("상품 정보")]),_c('div',{staticClass:"product-detail__product-info-sentence",domProps:{"textContent":_vm._s(_vm.productDetail.description)}})]),_c('div',{staticClass:"product-detail__delivery-info"},[_c('DetailCollapsible',{attrs:{"title":"배송 정보","detail":_vm.productDetail.deliveryContent}})],1),_c('div',{staticClass:"product-detail__refund-info"},[_c('DetailCollapsible',{attrs:{"title":"교환 / 환불 정보","detail":_vm.productDetail.refundContent}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-detail__abroad-img-wrap"},[_c('img',{staticClass:"product-detail__abroad-img",attrs:{"src":"https://tagbyimg.s3.ap-northeast-2.amazonaws.com/assets/store/abroad-ticket-icon.png","width":"38","height":"30"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-detail__description-container"},[_c('strong',{staticClass:"product-detail__description-title"},[_vm._v("상세 설명")])])}]

export { render, staticRenderFns }