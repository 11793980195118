






















import { defineComponent, computed } from "@vue/composition-api";

import formatNumber from "@/components/Hooks/formatNumber";

import { Option } from "@/components/Product/Types";

export default defineComponent({
  props: {
    option: {
      type: Object as () => Option,
      required: true,
    },
  },
  emits: ["click"],
  setup(props, context) {
    const isSoldOut = computed(() => {
      if (props.option.stock === null) {
        return false;
      } else if (props.option.stock === 0) {
        return true;
      }

      return false;
    });

    const clickHandler = () => {
      if (props.option.stock === 0) {
        alert("재고가 없습니다.");
        return;
      }
      context.emit("click");
    };

    return {
      isSoldOut,
      clickHandler,
      formatNumber,
    };
  },
});
