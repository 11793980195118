import { getCurrentInstance, ref } from "@vue/composition-api";

import getLocalStorageCart from "@/components/Hooks/getLocalStorageCart";

import { SelectedProduct } from "@/components/Product/Types";

export default () => {
  const instance = getCurrentInstance();

  const isCartMessageVisible = ref(false);

  const hasSameBaseOption = (
    curProduct: SelectedProduct,
    targetProducts: SelectedProduct[]
  ) => {
    const curProductNo = curProduct.productNo;
    const curLastBaseOptionIdx =
      curProduct.options.length > 0
        ? curProduct.options.slice(-1)[0].optionIdx
        : null;

    let isSame = false;

    for (const product of targetProducts) {
      const hasTextOption = product.options.some(
        (option) => option.type === "TEXT"
      );

      if (hasTextOption) {
        continue;
      }

      const targetProductNo = product.productNo;
      const targetLastBaseOptionIdx =
        product.options.length > 0
          ? product.options.slice(-1)[0].optionIdx
          : null;

      if (
        targetProductNo === curProductNo &&
        targetLastBaseOptionIdx === curLastBaseOptionIdx
      ) {
        isSame = true;
        break;
      }
    }

    return isSame;
  };

  const addCart = (shopIdx: number, newProducts: SelectedProduct[]) => {
    const wholeStoreCart = getLocalStorageCart() ?? {};

    const activeStoreCartProducts: SelectedProduct[] =
      wholeStoreCart && wholeStoreCart[shopIdx] ? wholeStoreCart[shopIdx] : [];

    // TODO: additionalOptions 처리 추가
    const filteredCartProducts = activeStoreCartProducts.filter(
      (product) => !hasSameBaseOption(product, newProducts)
    );

    const newCartProducts = [...newProducts, ...filteredCartProducts];

    Object.assign(wholeStoreCart, { [shopIdx]: newCartProducts });

    instance?.proxy.$store.commit("setCartItems", wholeStoreCart);

    localStorage.setItem("cart", JSON.stringify(wholeStoreCart));

    isCartMessageVisible.value = true;
  };

  return {
    isCartMessageVisible,
    addCart,
  };
};
