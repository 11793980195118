













































































import { defineComponent } from "@vue/composition-api";
import { SelectedProduct } from "@/components/Product/Types";

import ArrowDownIcon from "@/assets/ElementsImage/Arrow-Down.vue";
import MainTextInputIcon from "@/components/Common/MainTextInputIcon.vue";

export default defineComponent({
  components: {
    ArrowDownIcon,
    MainTextInputIcon,
  },
  emits: ["close", "open:base-option", "open:additional-option"],
  props: {
    selectedProducts: {
      type: Array as () => SelectedProduct[],
      required: true,
    },
    baseCategories: {
      type: Array as () => string[],
    },
    // TODO: 추가 상품 개발 필요
    additionalCategories: {
      type: Array as () => string[],
    },
    selectedBaseOptions: {
      type: Array as () => string[],
    },
  },
});
