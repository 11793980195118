export default () => {
  const showChannelButton = () => {
    if (window.ChannelIO) {
      window.ChannelIO("showChannelButton");
    }
  };

  const hideChannelButton = () => {
    if (window.ChannelIO) {
      window.ChannelIO("hideChannelButton");
    }
  };

  return {
    showChannelButton,
    hideChannelButton,
  };
};
